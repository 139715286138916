import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Stack, Image, Heading } from '@chakra-ui/react';
import Form from './Form';

export default function ForgotPassword() {
  const { t } = webCore.contexts.useTranslation();
  const toast = webApp.theme.hooks.useToast();
  const navigate = webApp.router.useNavigate();

  const { mutate: forgotPassword, isLoading } =
    webCore.coreHooks.user.useForgotPassword();

  const onSubmit = (values) => {
    const onError = (e) => {
      toast({ status: 'error', title: e.message });
    };
    const onSuccess = () => {
      toast({ status: 'success', title: 'Check your email please' });
      navigate(`/dashboard/reset-password?identifier=${values.identifier}`);
    };

    forgotPassword(values, { onError: onError, onSuccess: onSuccess });
  };

  return (
    <Stack
      maxW="581px"
      w="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      bg="white"
      borderRadius="12px"
      px={11}
      py={8}
    >
      <Image
        alt="logo"
        src="/.app-branding/logo/generated/svg/square-colored.svg"
        w="80px"
        h="80px"
        objectFit="cover"
      />
      <Stack spacing={2} alignItems="center" justifyContent="center" maxW={291}>
        <Heading as="h3" variant="h3-bold">
          {t('AppRecoverPassword')}
        </Heading>
      </Stack>
      <Form onSubmit={onSubmit} isLoading={isLoading} />
    </Stack>
  );
}
